.trainerstate {
    .icon {
        height: 100%;
        width: 100%;

        &--wrapper {
            width: 3rem;
            height: 3rem;
            margin: auto;
            margin-right: 2rem;
        }
    }

    .device {
        &--title {
            width: 20rem;
            min-width: fit-content;
            // height: 3rem;
            border-bottom: 1px solid $wt-red;

            min-height: 2rem;
            display: flex;
            align-items: baseline;
            .title {
                margin-left: 1rem;
            }
        }

        &--info {
            height: 6rem;
            // width: 10rem;

            .key {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }
        }
    }
}
