.expedition-map {
    width: 100%;
    height: 28rem;
    margin-top: 6rem;
    box-sizing: border-box;

    .text {
        margin-bottom: 2rem;
    }

    .map-wrapper {
        width: 100%;
        height: 100%;
        background-color: $wt-grey-2;
        box-sizing: border-box;

        @media screen and (max-width: $l-breakpoint) {
            height: 20rem;
        }
    }

    @media screen and(max-width: $l-breakpoint) {
        height: fit-content;
    }
}
