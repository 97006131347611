// All commonly used colors
$wt-red: #d71524;
$wt-red--dark: #b61323;

$wt-grey-0: #000000;
$wt-grey-1: #292929;
$wt-grey-2: #454545;
$wt-grey-3: #5a5a5a;
$wt-grey-4: #767676;
$wt-grey-5: #ababab;
$wt-grey-6: #bcbcbc;
$wt-grey-7: #d9d9d9;
$wt-grey-8: #fcfcfc;

$xs-breakpoint: 360px;
$s-breakpoint: 480px;
$sm-breakpoint: 640px;
$m-breakpoint: 800px;
$ml-breakpoint: 960px;
$l-breakpoint: 1080px;
$xl-breakpoint: 1280px;
$xxl-breakpoint: 1600px;

$font-s: 14px;
$font-sm: 16px;
$font-m: 20px;
$font-ml: 24px;
$font-l: 48px;
$font-xl: 64px;
$font-xxl: 86px;
