.trackpanel {
    &-verbose {
        height: 24rem;
        width: 100%;
        margin-bottom: 1rem;

        .image-wrapper {
            height: 24rem;
            width: 24rem;

            .imgpanel {
                height: 24rem;
                margin: 0;
                width: 24rem;

                // @media screen and (max-width: $l-breakpoint) {
                // }
            }
        }

        .content-wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            .content {
                background-color: #f9f9f9;
                display: flex;
                flex-direction: column;
                margin-left: 1rem;
                padding: 2rem 4rem;
                height: 100%;
                box-sizing: border-box;

                .btn-wrapper {
                    margin-left: auto;
                    margin-top: auto;
                    width: 13rem;
                }
            }
        }
    }
}

// title & distance & elevation
.trackpanel-verbose .content-wrapper .content .data-wrapper {
    border-bottom: 1px solid $wt-grey-5;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .title {
        color: $wt-red;
    }
}

// description part
.trackpanel-verbose .content-wrapper .content .description-wrapper {
    .description {
        color: $wt-grey-3;
        line-height: 1.3rem;
        letter-spacing: 0.05rem;
        margin-bottom: 0.5rem;
    }
}
// btn
.trackpanel-verbose .content-wrapper .content .btn-wrapper {
    .btn {
        background-color: $wt-grey-7;
        border: 0;
        width: fit-content;

        &:hover {
            background-color: $wt-grey-6;

            .text {
                color: $wt-grey-2;
            }
        }

        .text {
            color: $wt-grey-4;
            font-weight: 500;
            transition: color 0.5s;
        }
        .icon {
            margin-left: 1rem;
        }
    }
}

//responsive
.trackpanel-verbose {
    @media screen and (max-width: $l-breakpoint) {
        height: 16rem;

        .image-wrapper {
            height: 16rem;
            width: 16rem;

            .imgpanel {
                height: 16rem;
                width: 16rem;
            }
        }

        .content-wrapper {
            .content {
                padding: 1rem;

                .data-wrapper {
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                    height: 2rem;
                    height: fit-content;
                }

                .description-wrapper {
                    width: unset;
                    height: 8rem;
                    overflow: hidden;
                    // white-space: nowrap;
                    overflow: hidden;
                    text-overflow: initial;
                }

                .btn-wrapper {
                    height: 2rem;
                    .btn {
                        .text {
                            line-height: 1rem;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $ml-breakpoint) {
        .image-wrapper {
            height: 8rem;
            width: 8rem;
            margin: auto;
            .imgpanel {
                height: 8rem;
                width: 8rem;
            }
        }
    }

    @media screen and (max-width: $sm-breakpoint) {
        flex-direction: column;
        height: fit-content;
        .image-wrapper {
            height: 16rem;
            width: 16rem;

            .imgpanel {
                height: 16rem;
                width: 16rem;
            }
        }

        .content-wrapper {
            .content {
                margin: 0;

                .data-wrapper {
                    flex-wrap: wrap;
                    justify-content: center;
                    height: fit-content;
                    .data {
                        margin-left: 1rem;
                    }

                    .title {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $s-breakpoint) {
        .content-wrapper {
            .content {
                .description-wrapper {
                    .description {
                        font-size: $font-s;
                        line-height: 1rem;
                        letter-spacing: 0;
                    }
                }
                .data-wrapper {
                    .title {
                        text-align: center;
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $xs-breakpoint) {
        .image-wrapper {
            height: 100%;
            width: 100%;

            .imgpanel {
                height: 100%;
                width: 100%;
            }
        }

        .content-wrapper {
            .content {
                .btn-wrapper {
                    width: fit-content;
                    height: fit-content;
                    .btn {
                        padding: 0.25rem;
                        .text {
                            font-size: $font-s;
                        }
                    }
                }
                .data-wrapper {
                    .data {
                        margin: 0;
                        flex-wrap: wrap;
                        justify-content: center;
                        .stat {
                            margin-bottom: 0.25rem;
                        }
                    }
                    .title {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
