.pagination {
    .btn {
        width: 10rem;
        border: 0;
        background-color: #efefef;

        .text {
            color: $wt-grey-4;
            @media screen and (max-width: $sm-breakpoint) {
                display: none;
            }
        }

        &:hover {
            background-color: $wt-grey-7;
        }

        &.disabled {
            background: none;
        }
    }

    .previous {
        flex-direction: row-reverse;

        .icon {
            transform: rotate(180deg);
            margin-right: 0.5rem;
        }
    }

    .next {
        .icon {
            margin-left: 0.5rem;
        }
    }

    .indexes {
        margin: 0 6rem;
        @media screen and (max-width: $s-breakpoint) {
            margin: 0 1rem;
        }
        .text {
            margin-right: 0.5rem;
            text-decoration: none;
            color: $wt-grey-1;
            cursor: pointer;

            &.active {
                color: $wt-grey-6;
                cursor: default;
            }
        }
    }
}
