.video-controls {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .overlay {
        // width: 100%;
        // height: 100%;
    }
}
