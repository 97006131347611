.page-trainer {
    width: 100%;

    .scan-btn {
        width: 10rem;
    }

    .trainers {
        width: 50rem;
        margin: auto;

        .device-btn {
            width: 15rem;
            border: none !important;
            background-color: $wt-grey-7;
        }
    }
}
