.videopage {
    width: 100%;

    .more-btn {
        width: 20rem;
        margin-left: 5rem;

        @media screen and (max-width: $s-breakpoint) {
            width: fit-content;
        }
    }
}
