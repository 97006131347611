.imgpanel {
    width: 20rem;
    height: 20rem;
    margin: 0.5rem;
    position: relative;

    @media screen and (max-width: $l-breakpoint) {
        width: 15rem;
        height: 15rem;
    }

    &:hover {
        .sub {
            &::before {
                height: 100%;
            }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .sub {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0%;
            bottom: 0;
            left: 0;
            background-color: black;
            opacity: 0.5;
            transition: height 0.5s ease-in-out;
        }

        &:hover {
            .subcontent {
                .panel {
                    .tracktitle {
                        opacity: 1;
                        transition: opacity ease-in 0.5s 0.5s;
                    }

                    .description {
                        opacity: 1;
                        transition: opacity ease-in 0.5s 0.6s;
                    }

                    .author {
                        opacity: 1;
                        transition: opacity ease-in 0.5s 0.7s;
                    }
                }
            }
        }
        .subcontent {
            // position: ;
            height: 100%;
            padding: 0.5rem;
            padding-bottom: 0;
            margin-bottom: 0;

            .panel {
                height: calc(100% - 1rem);

                .tracktitle {
                    color: $wt-grey-8;
                    text-align: center;
                    height: 20%;
                }

                .description {
                    color: white;
                    height: 70%;
                    overflow: hidden;
                }

                .author {
                    color: $wt-grey-8;
                    text-align: end;
                    height: 10%;
                }

                .base {
                    transition: opacity 0.2s;
                    opacity: 0;
                    position: relative;
                }
            }
        }
    }

    &.alternative {
        position: relative;
        .sub {
            &::before {
                background-color: $wt-red;
                opacity: 0.8;
            }

            .subcontent {
                margin-top: calc(100% - 4rem);
                height: 4rem;
                padding: 0 0.5rem;
                .panel {
                    height: 100%;
                }

                .tracktitle {
                    height: calc(100% - 0.5rem);
                    text-transform: uppercase;
                    font-size: $font-m;
                    padding-top: 0.5rem;
                }

                .description,
                .author {
                    display: none;
                }
            }
        }

        &:hover {
            .sub {
                &::before {
                    height: 4rem;
                }
            }
        }
    }
}
