.video-description {
    width: 100%;
    min-height: 30rem;
    height: fit-content;

    .inner {
        padding: 6rem;

        .title--wrapper {
            padding-bottom: 1rem;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 45%;
                height: 1px;
                background-color: $wt-grey-5;
            }
        }
        .text-wrapper {
            min-height: 30rem;
            height: fit-content;

            @media screen and (max-width: $l-breakpoint) {
                flex-direction: column;
            }

            .heading-wrapper {
                margin-bottom: 2rem;

                .heading {
                    color: $wt-grey-3;
                    margin-top: 1rem;
                    line-height: 2rem;
                }
            }

            .feature-wrapper {
                margin-top: 1.5rem;
                .plus {
                    width: 1rem;
                    height: 1rem;
                    margin-right: 2rem;
                }
                .feature {
                    color: $wt-grey-3;
                }
            }

            .description-wrapper {
                margin-top: 1rem;

                .description {
                    color: $wt-grey-3;
                    line-height: 1.75rem;
                }
            }

            .half {
                width: 50%;

                &.left {
                    padding-right: 8rem;
                }

                &.right {
                    padding-left: 8rem;
                }
                @media screen and (max-width: $l-breakpoint) {
                    width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: $xxl-breakpoint) {
        .inner {
            .text-wrapper {
                .half {
                    &.left {
                        padding-right: 6rem;
                    }

                    &.right {
                        padding-left: 6rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $xl-breakpoint) {
        .inner {
            .text-wrapper {
                .half {
                    &.left {
                        padding-right: 3rem;
                    }

                    &.right {
                        padding-left: 3rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $l-breakpoint) {
        .inner {
            .text-wrapper {
                .half {
                    &.left {
                        padding-right: 3rem;
                    }

                    &.right {
                        margin-top: 2rem;
                        padding-left: 0rem;
                        padding-right: 3rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $ml-breakpoint) {
        .inner {
            .title--wrapper {
                &::after {
                    width: 80%;
                }
            }
        }
    }

    @media screen and (max-width: $m-breakpoint) {
        .inner {
            padding: 4rem;

            .text-wrapper {
                .half {
                    .heading {
                        font-size: $font-m;
                        line-height: 1.5rem;
                    }

                    .feature-wrapper {
                        margin-top: 1rem;
                        .feature {
                            font-size: $font-sm;
                        }
                    }

                    .description {
                        font-size: $font-s;
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $sm-breakpoint) {
        .inner {
            padding: 2rem;

            .text-wrapper {
                .half {
                    &.left {
                        padding: 0;
                    }

                    &.right {
                        padding: 0;
                    }

                    .heading {
                        font-size: $font-m;
                        line-height: 1.5rem;
                    }

                    .feature-wrapper {
                        margin-top: 1rem;
                        .feature {
                            font-size: $font-sm;
                        }
                    }

                    .description {
                        font-size: $font-s;
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $s-breakpoint) {
        .inner {
            .title {
                font-size: $font-ml;
            }
            .text-wrapper {
                .half {
                    .heading {
                        font-size: $font-sm;
                        line-height: 1.5rem;
                    }

                    .feature-wrapper {
                        margin-top: 1rem;
                        .feature {
                            font-size: $font-s;
                        }
                        .plus {
                            margin-right: 1rem;
                        }
                    }

                    .description {
                        font-size: $font-s;
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }
}
