.trackpanel {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    margin-left: 1rem;
    margin-right: 1rem;

    @media screen and (max-width: $m-breakpoint) {
        width: 16rem;
        // height: 16rem;
    }

    &-wrapper {
        margin-bottom: 3rem;

        @media screen and (max-width: $xxl-breakpoint) {
            // margin-left: 1rem;
            // margin-right: 1rem;
            margin-bottom: 4rem;
        }
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        width: 0;
        background-color: $wt-red;
        transition: width 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &:hover {
        &::after {
            width: 100%;
        }

        .sub {
            &::before {
                height: 100%;
            }

            .lines {
                opacity: 1;
                transition: opacity 0.5s;
                transition-delay: 0.5s;
            }

            .description {
                opacity: 1;
                transition: opacity 0.5s;
                transition-delay: 0.5s;
            }
        }
    }
    .title {
        color: $wt-red;
        font-weight: 500;
        font-style: italic;
        margin: 1rem 0;
        overflow: hidden;
    }

    .img-wrapper {
        background-color: black;
        width: 24rem;
        height: 24rem;
        position: relative;

        @media screen and (max-width: $m-breakpoint) {
            width: 16rem;
            height: 16rem;
        }

        .img {
            width: 100%;
            height: 100%;
            background-color: black;
            transition: opacity 300ms;
            object-fit: cover;
        }

        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            opacity: 0;
            transition: opacity 0.5s linear;
        }

        &:hover {
            .img {
                opacity: 0;
            }

            .video {
                display: block;
                opacity: 1;
            }
        }
    }
    // .img {
    //     width: 100%;
    //     height: 100%;
    //     background-color: black;
    //     transition: opacity 200ms;
    //     object-fit: cover;

    //     &-wrapper {
    //         background-color: black;
    //         width: 24rem;
    //         height: 24rem;
    //         position: relative;

    //         @media screen and (max-width: $m-breakpoint) {
    //             width: 16rem;
    //             height: 16rem;
    //         }

    //         .video {

    //         }
    //     }
    // }

    .sub {
        position: absolute;
        // display: none;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0%;
            bottom: 0;
            left: 0;
            background-color: black;
            opacity: 0.5;
            transition: height 0.5s ease-in-out;
        }

        .lines {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0s;
        }

        .description {
            position: absolute;
            top: 4rem;
            margin: 0 2rem;
            opacity: 0;
            transition: opacity 0s;
            color: $wt-grey-8;

            @media screen and (max-width: $m-breakpoint) {
                font-size: $font-s;
            }
        }
    }
}
