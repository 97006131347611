.videopage-details {
    padding: 6rem;
    background-color: $wt-grey-1;

    @media screen and (max-width: $l-breakpoint) {
        padding: 4rem;
    }

    @media screen and (max-width: $m-breakpoint) {
        padding: 2rem;
    }

    @media screen and (max-width: $s-breakpoint) {
        padding: 1rem;
    }

    .content-wrapper {
        margin-bottom: 2rem;
        height: 43rem;
        width: 100%;
        box-sizing: border-box;

        .title {
            margin-bottom: 1rem;
        }

        .map-wrapper {
            // width: 20rem;
            // 100% width minus the width that the video takes up
            width: calc(100% - 1200px);

            .inner {
                width: 100%;
                height: 40rem;

                background-color: $wt-grey-2;
            }
        }

        .video-wrapper {
            box-sizing: border-box;
            overflow: hidden;

            &.no-video {
                height: 640px;
            }

            .inner {
                height: 40rem;
                justify-content: flex-end;
                position: relative;

                &.error {
                    height: 40rem !important;
                }

                .video {
                    height: 100%;
                    width: auto;
                }
            }
        }

        @media screen and (max-width: calc($xxl-breakpoint + 80px)) {
            flex-direction: column-reverse;
            height: fit-content;
            align-items: center;

            .map-wrapper {
                width: 100%;
                height: fit-content;
                margin-top: 2rem;

                .inner {
                    height: 20rem;
                }
            }
        }

        @media screen and (max-width: calc($xl-breakpoint + 80px)) {
            .video-wrapper {
                height: fit-content;
                width: 100%;

                .inner {
                    width: 100%;
                    height: auto;

                    .video {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        @media screen and (max-width: $l-breakpoint) {
            .map-wrapper {
                .inner {
                    height: 15rem;
                }
            }
        }
    }
}

.videopage-details .content-wrapper .video-wrapper-inner {
    &.fs {
        position: relative;
        height: 100%;
        width: auto;
        margin: auto;
        display: flex;
        .video {
            width: 100% !important;
        }
    }

    &.nofs {
        position: relative;
        display: inherit;

        width: 100%;
        height: 100%;
    }
}
