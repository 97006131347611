.part-about {
    min-height: 50rem;
    width: 100%;

    .inner {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        .lines {
            position: absolute;

            &.right {
                top: 0;
                right: 0;
            }

            &.left {
                top: 8rem;
                left: 10rem;
            }
        }
        .img {
            position: absolute;
            width: 40rem;
            height: 40rem;
            object-fit: cover;
            top: 0;
            left: 0;
        }
        .text-wrapper {
            width: 40rem;
            padding: 4rem;
            // margin-top: 4rem;
            margin-right: 10%;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: 1;

            .title--wrapper {
                padding: 1.5rem 0;
                border-bottom: 1px solid $wt-grey-7;

                .title {
                    color: $wt-grey-1;
                }
            }

            .description-wrapper {
                margin-top: 1.5rem;

                .text {
                    color: $wt-grey-3;
                    margin-bottom: 2rem;
                    line-height: 2.2rem;
                }
            }

            .feature-wrapper {
                .plus {
                    height: 1.4rem;
                    width: 1.4rem;
                    margin-right: 1rem;
                }

                .feature {
                    color: $wt-grey-3;
                    margin-top: 1rem;
                }
            }
        }
    }

    .slider {
        height: 4rem;

        .marquee {
            height: 100%;

            &-text {
                margin-right: 1rem;
            }
        }
    }

    @media screen and (max-width: $xxl-breakpoint) {
        .inner {
            .img {
                width: 25rem;
                height: 25rem;
                left: 1rem;
                top: 5rem;
            }
        }
    }

    @media screen and (max-width: calc($xl-breakpoint + 50px)) {
        .inner {
            .img {
                // width: 40rem;
                // height: 40rem;
                // left: 50%;
                // top: 50%;
                // transform: translate(-50%, -50%);
                display: none;
            }

            .text-wrapper {
                .title {
                    font-size: 32px;
                }
            }
        }
    }
    @media screen and (max-width: $l-breakpoint) {
        .inner {
            .text-wrapper {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: $ml-breakpoint) {
        .description-wrapper {
            .text {
                font-size: $font-sm;
                line-height: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }
        .feature-wrapper {
            .plus {
                height: 1rem;
                width: 1rem;
                margin-right: 1rem;
            }

            .feature {
                color: $wt-grey-3;
                margin-top: 1rem;
                .text {
                    font-size: $font-s;
                }
            }
        }
    }

    @media screen and (max-width: $sm-breakpoint) {
        .inner {
            .text-wrapper {
                padding: 2rem;

                .description-wrapper {
                    .text {
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $xs-breakpoint) {
        .inner {
            .text-wrapper {
                padding: 0.5rem;

                .title--wrapper {
                    .title {
                        font-size: $font-ml;
                    }
                }
            }
        }
    }
}
