.tracklist {
    margin: 0 6.5rem;

    @media screen and (max-width: $l-breakpoint) {
        margin: 0 3rem;
    }

    @media screen and (max-width: calc($sm-breakpoint + 60px)) {
        margin: 0;
    }

    &-wrapper {
        width: 100%;
        margin-bottom: 8rem;
    }
    .segment-title {
        background-color: white;
        z-index: 1;
        padding: 0 3rem;
        margin: 2rem 0;

        @media screen and (max-width: $sm-breakpoint) {
            text-align: center;
            padding: 0 1rem;
            font-size: $font-ml;
        }

        &-wrapper {
            position: relative;
            overflow: hidden;
            &::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $wt-red;
            }
        }
    }

    .btn {
        &-wrapper {
            width: 15rem;
            margin: auto;
            margin-top: 3rem;
        }
    }
}
