.search {
    flex-wrap: wrap;
    width: 80rem;
    // margin: auto;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: calc($xl-breakpoint + 80px)) {
        justify-content: center;
    }

    @media screen and (max-width: $xl-breakpoint) {
        width: 60rem;
    }

    @media screen and (max-width: $ml-breakpoint) {
        width: 40rem;
    }

    @media screen and (max-width: $sm-breakpoint) {
        width: 90%;
    }

    .is {
        @media screen and (max-width: $l-breakpoint) {
            margin-top: 1rem;
        }
    }

    &-is1 {
        width: 28%;
        margin-right: 1%;

        @media screen and (max-width: calc($xl-breakpoint + 80px)) {
            width: 32%;
            margin-right: 0;
        }

        @media screen and (max-width: $l-breakpoint) {
            width: 48%;
        }
        @media screen and (max-width: $sm-breakpoint) {
            width: 90%;
        }
    }

    &-is2 {
        width: 28%;
        margin-right: 1%;

        @media screen and (max-width: calc($xl-breakpoint + 80px)) {
            width: 32%;
            margin-right: 0;
        }
        @media screen and (max-width: $l-breakpoint) {
            width: 48%;
        }
        @media screen and (max-width: $sm-breakpoint) {
            width: 90%;
        }
    }

    &-is3 {
        width: 28%;
        margin-right: 1%;

        @media screen and (max-width: calc($xl-breakpoint + 80px)) {
            width: 32%;
            margin-right: 0;
        }
        @media screen and (max-width: $l-breakpoint) {
            width: 48%;
            margin: 0 25%;
        }
        @media screen and (max-width: $sm-breakpoint) {
            width: 90%;
            margin: 0;
        }
    }

    &-isbtn {
        @media screen and (max-width: calc($xl-breakpoint + 80px)) {
            margin: 1rem 0;
        }

        width: 8rem;
    }
}
