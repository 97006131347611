.poi-warning {
    background-color: rgba(30, 30, 30, 0.85);
    position: absolute;
    right: 0;
    top: 50%;
    width: 15rem;
    padding: 1rem;
    transform: translateX(0);
    transition: opacity 0.6s ease-in-out, transform 0.5s;
    display: block;

    &.disable {
        opacity: 0;
        transform: translateX(100%);
    }
}
