.heightmap {
    // background-color: $wt-red;
    height: 160px;

    &-user {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        filter: drop-shadow(1px 5px 5px #222);
        padding: 0;
        margin: 0;
        z-index: 1;

        &.undef {
            display: none;
        }
    }

    &-poi {
        position: absolute;
        padding: 0;
        margin: 0;
        width: 1rem;
        height: 1rem;
        cursor: pointer;

        &.time-undef {
            display: none;
        }
    }

    .gpx {
        background-color: rgba(0, 0, 0, 0.6);
    }
}
