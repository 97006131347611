.expeditionstatus {
    box-sizing: border-box;
    height: 32rem;
    width: 26rem;
    padding: 2rem;

    &.hide {
        @media screen and (max-width: $xxl-breakpoint) {
            display: none;
        }
    }

    .buy {
        width: 16rem;
        height: 2rem;
        position: relative;
        padding: 0.5rem 0;

        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: $wt-grey-1;
            right: 3rem;
            bottom: 0;
            transition: background-color 0.3s 0.2s;
        }

        &:hover {
            .buy-icon {
                path {
                    fill: white;
                }
            }
            &::after {
                background-color: white;
            }
        }
        &-text {
            width: 14rem;
            text-align: center;
            position: relative;
        }

        &-icon {
            width: 1.5rem;
            height: 1.5rem;
            padding: 0 0.75rem;
            path {
                transition: fill 0.3s 0.2s;
            }
        }
    }

    .all {
        width: 11rem;
        padding: 0.5rem;
        background-color: $wt-grey-3;
        color: white;

        &:hover {
            color: $wt-grey-8 !important;
            background-color: $wt-grey-2 !important;
            transition: background-color 0.2s;
        }
    }

    @media screen and (max-width: $m-breakpoint) {
        height: fit-content;
        width: 100%;
        background: linear-gradient(
            90deg,
            $wt-grey-2 0%,
            rgba(255, 255, 255, 0) 100%
        );
        .buy {
            margin-top: 2rem;
        }

        .all {
            margin-top: 2rem;
        }
    }
    @media screen and (max-width: $s-breakpoint) {
        .buy {
            width: fit-content;
            &-text {
                width: fit-content;
                padding: 0 0.5rem;
            }
        }
    }
}
