.track-details {
    padding: 1rem 2rem;
    box-sizing: border-box;
    background: rgb(248, 248, 248);
    background: linear-gradient(
        90deg,
        $wt-grey-2 0%,
        rgba(255, 255, 255, 0) 100%
    );

    .title--wrapper {
        padding: 1rem 0;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -2rem;
            width: 80%;
            height: 1px;
            background-color: $wt-grey-5;
        }
    }
    .data--wrapper {
        padding: 1rem 0;
    }
    .stat {
        margin-left: 3rem;
        .value {
            color: white !important;
        }
        .unit {
            color: white !important;
        }
    }

    @media screen and (max-width: $l-breakpoint) {
        flex-direction: column;

        .inner {
            width: 100%;
        }
    }

    @media screen and (max-width: $m-breakpoint) {
        .data--wrapper {
            flex-direction: column;
            align-items: flex-start;

            .stat {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }
}
