.searchpart {
    height: 50rem;
    width: 100%;
    position: relative;
    overflow: hidden;

    .half {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;

        &.r {
            background-image: url("/assets/imgs/bike-r.png");
            background-repeat: no-repeat;
            background-position: center;
            right: 0;

            @media screen and (max-width: $m-breakpoint) {
                width: 0%;
                display: none;
            }
        }

        &.l {
            background-image: url("/assets/imgs/bike-l.png");
            background-repeat: no-repeat;
            background-position: center;
            left: 0;

            @media screen and (max-width: $m-breakpoint) {
                width: 100%;
            }
        }
    }

    .content {
        position: relative;
        margin-top: 15rem;

        .title {
            color: $wt-grey-8;
            text-align: center;
            margin-bottom: 2rem;

            @media screen and (max-width: $xl-breakpoint) {
                font-size: $font-xl;
            }
            @media screen and (max-width: $ml-breakpoint) {
                font-size: $font-l;
            }
            @media screen and (max-width: $sm-breakpoint) {
                font-size: $font-ml;
            }
        }

        .trackwrapper {
            background-color: white;

            @media screen and (max-width: calc($xl-breakpoint + 80px)) {
                background: none;
            }
        }

        .promowrapper {
            display: flex;
            flex-wrap: wrap;
            width: 80rem;
            margin: auto;
            margin-top: 1rem;
            justify-content: center;

            @media screen and (max-width: calc($xl-breakpoint + 80px)) {
                width: fit-content;
            }

            @media screen and (max-width: $ml-breakpoint) {
                flex-direction: column;
            }

            .line {
                // margin-left: 0.5rem; // margin-left to compensate for transformations below (alignment)
                // margin-right: 2rem;
                color: $wt-grey-7;
                transform: rotate(45deg) scale(1.5);
                display: none;

                @media screen and (max-width: $ml-breakpoint) {
                    margin-top: 1rem;
                }

                @media screen and (max-width: $xs-breakpoint) {
                    display: none;
                }

                &.show {
                    display: inline;
                }

                &.secondary {
                    display: none;

                    @media screen and (max-width: $ml-breakpoint) {
                        display: inline;
                    }
                    @media screen and (max-width: $xs-breakpoint) {
                        display: none;
                    }
                }

                &.responsive {
                    @media screen and (max-width: $ml-breakpoint) {
                        display: inline;
                    }
                    @media screen and (max-width: $xs-breakpoint) {
                        display: none;
                    }
                }
            }
        }

        .promo {
            text-transform: uppercase;
            color: $wt-grey-7;
            margin: 0 2rem;
            // margin-left: 0.5rem;
            // margin-right: 2rem;

            @media screen and (max-width: $ml-breakpoint) {
                width: 100%;
                text-align: center;
                margin-top: 1rem;
            }
        }

        .btn-wrapper {
            width: 25rem;
            margin: auto;
            @media screen and (max-width: $m-breakpoint) {
                width: 15rem;
            }

            .btn {
                padding-top: 1rem;
                padding-bottom: 1rem;
                text-align: center;
            }
        }
    }
}
