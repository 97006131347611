.footer {
    background-color: $wt-grey-1;
    width: 100%;
    height: 20rem;

    @media screen and (max-width: $xl-breakpoint) {
        height: fit-content;
        padding-bottom: 2rem;
    }
    @media screen and (max-width: $ml-breakpoint) {
        height: fit-content;
    }
    &-marquee {
        height: 100%;

        &-wrapper {
            height: 4.75rem;
            margin-top: 3rem;
        }
    }

    .innerMarqueeWrapper {
        align-items: center;
        margin-right: 5rem;

        .arrow {
            width: 4rem;
            height: 4rem;
            font-size: 4rem;
            margin-left: 1rem;
        }

        .marqueetext {
            color: white;
        }
    }

    .newsletter {
        width: 100%;
        min-height: 3rem;
        height: fit-content;
        border-top: 2px solid $wt-red;
        border-bottom: 2px solid $wt-red;
        margin: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;

        @media screen and (max-width: $xl-breakpoint) {
            flex-direction: column;

            .form {
                flex-direction: column;
                align-items: center;
            }
            .info {
                margin-top: 1rem;
            }

            .btn-wrapper {
                margin-top: 1rem;
            }
        }

        @media screen and (max-width: $s-breakpoint) {
            .form {
                .input {
                    width: 90%;
                }
            }
        }

        .line {
            margin-left: 0.5rem; // margin-left to compensate for transformations below (alignment)
            margin-right: 2rem;
            color: $wt-grey-7;
            transform: rotate(45deg) scale(1.5);

            @media screen and (max-width: $xs-breakpoint) {
                display: none;
            }
        }

        .promo {
            text-transform: uppercase;
            color: $wt-grey-7;
            margin-left: 0.5rem;
            margin-right: 2rem;

            @media screen and (max-width: $ml-breakpoint) {
                width: 100%;
                text-align: center;
                margin-top: 0.5rem;
            }
        }

        .btn {
            // height: 100%;
            // height: 1.75rem;
            height: 3rem;
            border-radius: 0;
            border: none;
            outline: none;
            background-color: $wt-red;
            color: white;
            text-transform: uppercase;
            padding: 0 1rem;
            font-style: italic;
            transition: background-color 0.3s ease-in, color 0.3s 0.1s ease-in;
            cursor: pointer;

            &:hover {
                background-color: white;
                color: $wt-red;
            }

            &-wrapper {
                // height: 100%;
                height: 3rem;
                display: block;
                position: relative;
            }
        }

        .input {
            background: none;
            border: 0;
            border-bottom: 1px solid $wt-grey-6;
            color: $wt-grey-8;
            height: 2rem;
            width: 25rem;
            margin: 0 2rem;

            &:active {
                border: 0;
                border-bottom: 1px solid $wt-grey-8;
            }

            &:focus-visible {
                outline: none;
                border-bottom: 1px solid $wt-grey-8;
            }
        }
    }

    .utility {
        align-items: center;
        padding: 0 4rem;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: calc($m-breakpoint + 80px)) {
            justify-content: center;
        }

        @media screen and (max-width: calc($sm-breakpoint + 100px)) {
            flex-direction: column;
        }

        @media screen and (max-width: $s-breakpoint) {
            padding: 0 1rem;
        }

        .policies {
            @media screen and (max-width: $m-breakpoint) {
                flex-wrap: wrap;
                padding-bottom: 4rem;
            }
            @media screen and (max-width: calc($sm-breakpoint + 100px)) {
                flex-direction: column;
            }
            .policy {
                color: white;
                margin: 0 2rem;
                text-transform: uppercase;
                &-wrapper {
                    @media screen and (max-width: calc($sm-breakpoint + 100px)) {
                        // margin: 1rem 0;
                        margin-top: 1rem;
                        text-align: center;
                    }
                }
            }
        }

        .social {
            color: white;
            background: none;
            height: 1.5rem;
            width: auto;
            margin: auto;
            &-wrapper {
                width: 2rem;
                height: 2rem;
                padding: 0.25rem;
                margin: 0 0.5rem;
                background-color: $wt-grey-3;
                border-radius: 10rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.3s ease-in-out,
                    transform 0.2s linear;

                @media screen and (max-width: calc($sm-breakpoint + 100px)) {
                    margin-top: 1rem;
                }

                &:hover {
                    &.ig {
                        background-color: #e1306c;
                        transform: scale(1.1);
                    }

                    &.fb {
                        background-color: #4267b2;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
