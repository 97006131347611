.track-error {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.visible {
        display: flex;
    }

    &.hidden {
        display: none;
    }
}
