//      __control
//          __value-container
//              __placeholder
//              __input-container
//                  __input
//      __indicators
//          __indicator-separator
//          __indicator
//              svg
//

// font-family and font-size used as its a workaround against react-select built-in font style (needs !important which @extend does not allow)
// font-family: "Rubik", sans-serif !important;
// font-size: 16px;

// !important used as various attributes need overrides

.input {
    &__control {
        border: 0 !important;
        border-radius: 0 !important;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0.5rem 2rem;
        margin-right: 0.05rem;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            height: 2rem;
            margin: auto;
            width: 1px;
            background-color: $wt-grey-6;
        }

        &--is-focused {
            box-shadow: 0 0 0 1px $wt-red !important;
        }
    }

    &__value-container {
        margin-right: 2rem;
    }

    &__single-value {
        font-family: "Rubik", sans-serif !important;
        font-size: 16px;
        text-transform: uppercase;
    }

    &__input {
        font-family: "Rubik", sans-serif !important;
        font-size: 16px;
        text-transform: uppercase !important;
    }

    &__placeholder {
        font-family: "Rubik", sans-serif !important;
        font-size: 16px;
        text-transform: uppercase;
    }

    &__indicator-separator {
        width: 0 !important;
    }

    &__menu {
        border-radius: 0px !important;
        &-list {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        &-notice {
            display: none;
        }
    }

    &__option {
        font-family: "Rubik", sans-serif !important;
        font-size: 16px;
        text-transform: uppercase;

        &--is-focused {
            background-color: $wt-grey-7 !important;
        }
        &--is-selected {
            background-color: $wt-grey-7 !important;
        }
    }
}

.input-dark {
    // to-do (needs design)
    display: inherit;
}
