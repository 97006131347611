.spinner {
    display: inline-block;
    width: 5rem;
    height: 5rem;

    &::after {
        content: "";
        display: block;
        width: 3rem;
        height: 3rem;
        margin: 1rem;
        border-radius: 50%;
        border: 0.25rem solid;
        border-color: $wt-red transparent;
        animation: spinner-animation 2s cubic-bezier(0.645, 0.045, 0.355, 1)
            infinite;
    }

    &.lg {
        width: 8rem;
        height: 8rem;

        &::after {
            width: 7rem;
            height: 7rem;
            margin: 0.5rem;
        }
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
