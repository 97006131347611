.connect {
    position: relative;
    width: 100%;
    height: 40rem;

    .half {
        &-text {
            width: 50%;
            padding: 8rem 4rem;
            padding-right: 8rem;
            background-color: $wt-grey-8;
            position: relative;

            @media screen and (max-width: $xl-breakpoint) {
                width: 100%;
            }

            @media screen and (max-width: $s-breakpoint) {
                // padding-right: 4rem;
                padding: 4rem 2rem;
                padding-top: 8rem;
            }

            .title {
                color: $wt-grey-1;
            }

            .separator {
                margin: 2rem 0;
                height: 1px;
                width: 100%;
                background-color: $wt-grey-5;
            }

            .para {
                color: $wt-grey-3;
                margin-bottom: 2rem;
            }
        }

        &-images {
            width: 50%;
            padding: 4rem;
            position: relative;

            @media screen and (max-width: $xl-breakpoint) {
                display: none;
            }
        }
    }

    .plus {
        position: absolute;
        top: 15rem;
        left: 17.5rem;
        animation: ellipse1 10s ease-in-out 0s infinite;

        &.second {
            top: 15rem;
            left: 15rem;
            animation: ellipse1 12s linear 0s infinite reverse;
        }

        &.alternative {
            @media screen and (min-width: $xl-breakpoint) {
                display: none;
            }
            animation: none;
            top: 7.5rem;
            right: 10rem;
            left: auto;

            @media screen and (max-width: $sm-breakpoint) {
                transform: scale(0.5);
                top: 5rem;
                right: 5rem;
            }
        }
    }

    .img-wrapper {
        position: absolute;
        overflow: hidden;

        &.prim {
            top: 2rem;
            left: 15rem;
        }

        &.sec {
            bottom: 10rem;
            left: 5rem;
        }

        &.tert {
            bottom: 8rem;
            left: 30rem;
        }

        .img {
            width: 10rem;
            height: 10rem;
            position: relative;
            transition: transform 500ms ease-in;
        }

        .distance {
            align-items: baseline;
        }
    }

    .rings {
        position: absolute;
        top: 5rem;
        justify-self: center;

        &-secondary {
            position: absolute;
            display: none;
            left: 30%;
            justify-self: center;
            opacity: 0.5;

            @media screen and (max-width: $xl-breakpoint) {
                top: 10%;
                display: inline;
            }

            @media screen and (max-width: $ml-breakpoint) {
                left: 10%;
            }

            @media screen and (max-width: $m-breakpoint) {
                transform: scale(0.75);
            }

            @media screen and (max-width: $sm-breakpoint) {
                display: none;
            }
        }

        &-wrapper {
            overflow: hidden;
        }
    }
}

@keyframes ellipse1 {
    0% {
        transform: rotate(0deg) translateX(150px) rotate(0deg);
    }

    50% {
        transform: rotate(180deg) translateX(90px) rotate(-180deg);
    }

    100% {
        transform: rotate(360deg) translateX(150px) rotate(-360deg);
    }
}
