.expedition-tracks {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;

    &-content {
        min-height: 120rem;
    }
    .error-wrapper {
        .btn {
            width: 20rem;

            @media screen and (max-width: $s-breakpoint) {
                width: 10rem;
            }
        }
    }

    .track-wrapper {
        width: 100%;
    }

    @media screen and (max-width: $s-breakpoint) {
        padding: 1rem;
    }

    @media screen and (max-width: $xs-breakpoint) {
        padding: 0.5rem;
    }
}
