.buy-btn {
    width: 16rem;
    height: 2rem;
    position: relative;
    padding: 0.5rem 0;

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: $wt-grey-1;
        right: 3rem;
        bottom: 0;
        transition: background-color 0.3s 0.2s;
    }

    &:hover {
        .buy-btn--icon {
            path {
                fill: white;
            }
        }
        &::after {
            background-color: white;
        }
    }
    &--text {
        width: 14rem;
        text-align: center;
        position: relative;
    }

    &--icon {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0 0.75rem;
        path {
            transition: fill 0.3s 0.2s;
        }
    }

    @media screen and (max-width: $s-breakpoint) {
        width: fit-content;

        &::after {
            width: 0px;
        }
        &--text {
            width: fit-content;
            padding: 0 2rem;
        }
        &--icon {
            display: none;
        }
    }
}
