.trainerpart {
    background-color: white;
    height: 50rem;
    width: 100%;
    margin-bottom: 20rem;

    @media screen and (max-width: $m-breakpoint) {
        margin-bottom: 4rem;
    }

    @media screen and (max-width: $s-breakpoint) {
        height: fit-content;

        margin-bottom: 0;
    }
    .slider {
        height: 4rem;

        .marquee {
            height: 100%;

            &-text {
                margin-right: 1rem;
            }
        }
    }

    .inner {
        background-color: $wt-red;
        height: 100%;
        margin: 0 6.5rem;
        margin-bottom: 4rem; //slider height margin from the bottom
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $l-breakpoint) {
            margin: 0 3rem;
        }
        @media screen and (max-width: $sm-breakpoint) {
            margin: 0;
        }

        .lines {
            position: absolute;
            opacity: 0.5;

            @media screen and (max-width: $xl-breakpoint) {
            }
            &.top {
                top: 4rem;
                left: 0;
            }
            &.btm {
                bottom: 1rem;
                right: -4rem;
                opacity: 0.5;
            }
        }

        .content {
            height: 100%;
            width: 100%;
            // margin: 8rem 0rem;
            // padding: 0 4rem;
            position: relative;

            .wrap {
                display: flex;
                margin: 0 4rem;
                padding: 8rem 0;
                position: inherit;
                height: fit-content;
                @media screen and (max-width: $ml-breakpoint) {
                    margin: 0 1rem;
                }
            }

            .trainer-img {
                background-image: url("/assets/imgs/bike-trainer.png");
                background-repeat: no-repeat;
                background-position: center;

                width: 50%;
                height: 40rem;

                @media screen and (max-width: calc($xl-breakpoint + 80px)) {
                    display: none;
                }

                &--abs {
                    background-image: url("/assets/imgs/bike-trainer.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    display: none;
                    @media screen and (max-width: calc($xl-breakpoint + 80px)) {
                        display: block;
                        position: absolute;
                        top: 5rem;
                        left: 0;
                        width: 100%;
                        height: 30rem;
                        margin: auto;
                        opacity: 0.5;
                    }
                }
            }

            .connect {
                margin-left: auto;
                margin-right: auto;
                // margin: auto;
                width: 40%;
                min-height: 30rem;
                height: fit-content;
                max-height: 100%;

                @media screen and (max-width: calc($xl-breakpoint + 80px)) {
                    width: 80%;
                }

                .separator {
                    margin-top: 2rem;
                    width: 90%;
                    height: 1px;
                    background-color: $wt-grey-7;
                    opacity: 0.5;

                    @media screen and (max-width: $s-breakpoint) {
                        margin-top: 1rem;
                    }
                }

                .title {
                    color: white;

                    @media screen and (max-width: $ml-breakpoint) {
                        font-size: $font-l;
                    }

                    @media screen and (max-width: $s-breakpoint) {
                        font-size: $font-ml;
                    }
                }

                .para {
                    color: white;
                    margin-top: 2rem;
                    line-height: 2rem;

                    @media screen and (max-width: $sm-breakpoint) {
                        font-size: $font-m;
                        line-height: 1.5rem;
                    }

                    @media screen and (max-width: $s-breakpoint) {
                        font-size: $font-sm;
                        line-height: 1.2rem;
                        margin-top: 1rem;
                    }
                }

                .btn {
                    width: fit-content;
                    padding: 1rem 1rem;
                    // margin: 0;
                    border: 0;

                    &-wrapper {
                        margin-top: 5rem;

                        @media screen and (max-width: $s-breakpoint) {
                            // display: none;
                            margin: auto;
                            margin-bottom: 0;
                        }
                    }

                    &-win {
                        @media screen and (max-width: $s-breakpoint) {
                            display: none;
                        }
                    }
                }

                .instruction {
                    margin-top: 1rem;
                    text-transform: uppercase;
                    color: white;
                    text-decoration: none;

                    @media screen and (max-width: $s-breakpoint) {
                        text-align: center;
                    }

                    &.link {
                        &:hover {
                            color: $wt-grey-7;
                        }
                    }
                }
            }
        }
    }
}
