@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

%wt-text {
    font-family: "Rubik", sans-serif;
}
// 14px, 16px, 20px, 24px, 48px, 64px, 86px
// s sm m ml l xl xxl
.text {
    margin: 0;
    padding: 0;
    text-decoration: none;

    &-s {
        @extend %wt-text;
        font-size: $font-s;
    }

    &-sm {
        @extend %wt-text;
        font-size: $font-sm;
    }

    &-m {
        @extend %wt-text;
        font-size: $font-m;
    }

    &-ml {
        @extend %wt-text;
        font-size: $font-ml;
    }

    &-l {
        @extend %wt-text;
        font-size: $font-l;
    }

    &-xl {
        @extend %wt-text;
        font-size: $font-xl;
    }

    &-xxl {
        @extend %wt-text;
        font-size: $font-xxl;
    }

    &-w300 {
        font-weight: 300;
    }
    &-w400 {
        font-weight: 400;
    }

    &-w500 {
        font-weight: 500;
    }

    &-w600 {
        font-weight: 600;
    }

    &-w700 {
        font-weight: 700;
    }

    &-cntr {
        text-align: center;
    }

    &-i {
        font-style: italic;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &-ff {
        color: white;
    }
    &-red {
        color: $wt-red;
    }
}
