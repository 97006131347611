.header {
    width: 100%;
    height: 4.5rem;
    .logo {
        height: 3rem;
        width: auto;

        @media screen and (max-width: $s-breakpoint) {
            height: auto;
            width: 12rem;
        }

        &-btn {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            // margin-top: auto;
            // margin-bottom: auto;
            // margin-top: 50%;
            // margin-bottom: 50%;

            @media screen and (max-width: $ml-breakpoint) {
                padding: 0.5rem;
            }
        }
    }

    .links {
        letter-spacing: 0.095rem;
        transition: background-color 0.05s
            cubic-bezier(0.95, 0.05, 0.795, 0.035);

        &-btn {
            width: 6rem;
            height: 100%;
            padding: 0 0.5rem;

            @media screen and (max-width: $m-breakpoint) {
                width: 100%;
                padding: 1rem 1rem;
            }
            align-items: center;
            justify-content: center;
        }

        &--trainer {
            color: $wt-red;
        }
    }

    .wrapper {
        margin: 0 2rem;
        width: 100%;
        border-bottom: 1px solid $wt-red;
        position: relative;

        @media screen and (max-width: $l-breakpoint) {
            justify-content: space-between;
        }

        &-logo {
            width: 25%;

            @media screen and (max-width: $ml-breakpoint) {
                width: 20%;
            }

            @media screen and (max-width: $m-breakpoint) {
                width: fit-content;
            }
        }

        &-links {
            width: 50%;
            height: 100%;
            margin: auto;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $m-breakpoint) {
                display: none;
            }
        }

        &-secondary {
            width: 25%;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: $l-breakpoint) {
                width: 20%;
            }
        }
    }

    // BURGIR BURGIR BURGIR BURGIR
    #burger-toggle {
        display: none;
    }

    .burger-wrapper {
        align-items: center;
        cursor: pointer;
        display: none;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 30px;

        @media screen and (max-width: $l-breakpoint) {
            display: flex;
        }
    }

    .burger,
    .burger::before,
    .burger::after {
        display: none;

        @media screen and (max-width: $l-breakpoint) {
            background-color: $wt-red;
            display: block;
            height: 4px;
            opacity: 1;
            width: 30px;
            position: absolute;
            transition: transform 0.5s ease-in-out, background 0.5s ease-in,
                margin 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            border-radius: 2px;
        }
    }
    .burger::before {
        content: "";
        margin-top: -8px;
    }
    .burger::after {
        content: "";
        margin-top: 8px;
    }

    #burger-toggle:checked + .burger-wrapper .burger::before {
        margin-top: 0px;
        transform: rotate(135deg);
    }
    #burger-toggle:checked + .burger-wrapper .burger {
        background: #fff;
    }
    #burger-toggle:checked + .burger-wrapper .burger::after {
        margin-top: 0px;
        transform: rotate(-135deg);
    }

    .icons {
        margin: 0 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .dwnl {
        @media screen and (max-width: $l-breakpoint) {
            display: none;
        }
    }

    .account {
        @media screen and (max-width: $ml-breakpoint) {
            display: none;
        }
    }

    .language {
        @media screen and (max-width: $ml-breakpoint) {
            display: none;
        }
    }

    .dropdown {
        &-btn {
            width: calc(100% - 2rem);
            min-height: 2.5rem;
            @media screen and (min-width: $m-breakpoint) {
                display: none;
            }
        }

        &-dwnl {
            @media screen and (max-width: $l-breakpoint) {
                width: calc(100% - 2rem);
                display: flex;
                min-height: 2.5rem;
            }

            @media screen and (min-width: $l-breakpoint) {
                display: none;
            }
        }

        &-extra {
            @media screen and (max-width: $ml-breakpoint) {
                width: calc(100% - 2rem);
                display: flex;
                padding: 0.5rem 1rem;
                min-height: 2.5rem;
            }

            @media screen and (min-width: $ml-breakpoint) {
                display: none;
            }
        }

        &-acc {
            display: none;

            @media screen and (max-width: $ml-breakpoint) {
                display: flex;
                width: calc(100% - 2rem);
                min-height: 2.5rem;
            }
        }

        &-nav {
            z-index: 10;
            display: none;
            flex-direction: column;
            position: absolute;
            top: 0;
            // right: 0;
            left: 0;
            min-height: 4.5rem;
            background-color: $wt-grey-8;
            // border: 1px solid lime;
            filter: drop-shadow(0px 1px 5px #222);

            &.opened {
                // border: 1px solid green;
                display: flex;
                transform: translateY(4.5rem);
                width: 100%;

                @media screen and (min-width: $l-breakpoint) {
                    display: none;
                }
            }
        }
    }

    .scroll-items {
        height: 100%;
    }

    .icon--download {
        path {
            fill: $wt-grey-2;
        }
    }
}
