.stat {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;

    @media screen and (max-width: $m-breakpoint) {
        margin-right: 1rem;
    }

    .icon {
        display: block;
    }

    .value-unit {
        display: flex;
        margin: 0 0.5rem;
        align-items: baseline;

        .value {
            color: $wt-grey-3;
            margin: 0 0.5rem;

            @media screen and (max-width: $m-breakpoint) {
                font-size: $font-s;
                margin-right: 0;
            }
        }

        .unit {
            color: $wt-grey-3;

            @media screen and (max-width: $m-breakpoint) {
                font-size: $font-s;
            }
        }
    }
}
