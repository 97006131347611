.page-tracks {
    width: 100%;

    .inner {
        min-height: 40rem;
    }

    .search-wrapper {
        width: 100%;
        justify-content: center;
        align-items: center;

        .picker {
            width: 18rem;

            @media screen and (max-width: $sm-breakpoint) {
                width: 100%;
            }
        }

        .submit {
            width: 10rem;
        }
    }
}
