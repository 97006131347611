.titlebar {
    padding: 4rem;
    background-color: $wt-red;
    position: relative;
    overflow: hidden;

    .lines {
        position: absolute;
        &.top {
            top: 0.8rem;
            right: 0;
        }
        &.bot {
            bottom: 0.8rem;
            right: 10rem;
        }
    }
    .title {
        color: white;
    }

    .titlebutton {
        width: 11rem;
        padding: 0.5rem;
        background-color: $wt-grey-8;
        color: $wt-red !important;

        &:hover {
            color: $wt-red--dark !important;
            background-color: $wt-grey-7 !important;
            transition: background-color 0.2s;
        }
    }

    &.flexible {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $sm-breakpoint) {
            flex-direction: column;
        }
    }
}
