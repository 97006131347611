.expedition-dd {
    width: 28rem;
    height: 32rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    background: rgb(248, 248, 248);
    background: linear-gradient(
        90deg,
        $wt-grey-2 0%,
        rgba(255, 255, 255, 0) 100%
    );

    @media screen and (max-width: $m-breakpoint) {
        height: fit-content;
        width: 100%;
    }

    &.hide {
        @media screen and (max-width: $xxl-breakpoint) {
            display: none;
        }
    }

    .data-title {
        color: white;
        font-weight: 400;
        &--wrapper {
            // margin: 1rem 0;
            padding: 1rem 0;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -2rem;
                width: 100%;
                height: 1px;
                // background-color: $wt-grey-6;
                background: linear-gradient(
                    90deg,
                    $wt-grey-6 0%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
        }
    }
    .data-details--wrapper {
        margin-top: 2rem;
        .value {
            color: white;
        }
        .unit {
            color: white;
        }
        .place {
            color: white;
            letter-spacing: 0.04rem;
        }
        .icon {
            margin-right: 1.5rem;
        }
        .stat {
            justify-content: flex-start;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}
