.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.column-r {
        flex-direction: column-reverse;
    }

    &.row-r {
        flex-direction: row-reverse;
    }

    &.just {
        &-c {
            justify-content: center;
        }

        &-sp {
            justify-content: space-between;
        }
        &-spe {
            justify-content: space-evenly;
        }

        &-e {
            justify-content: flex-end;
        }
    }

    &.al {
        &-c {
            align-items: center;
        }

        &-bl {
            align-items: baseline;
        }
    }
}

.margin {
    &-0 {
        margin: 0;
    }

    &-v1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &-v2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &-h05 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
