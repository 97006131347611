.expedition-details {
    width: 100%;

    .inner {
        padding: 6rem;
        background-color: $wt-grey-1;
        height: 80rem;

        .watch {
            color: white;
            margin-bottom: 1rem;
            margin-left: 0rem;
        }
        @media screen and (max-width: $xxl-breakpoint) {
            height: fit-content;

            .watch {
                margin-left: 0rem;
            }

            .content {
                flex-direction: column;
            }
        }

        @media screen and (max-width: $l-breakpoint) {
            padding: 3rem;
        }

        @media screen and (max-width: $s-breakpoint) {
            padding: 1rem;

            .watch {
                font-size: $font-sm;
            }
        }
    }

    .video-wrapper {
        height: 32rem;
        position: relative;

        @media screen and (max-width: $xxl-breakpoint) {
            width: 100%;
            height: auto;
        }
        .video {
            height: 100%;

            @media screen and (max-width: $xxl-breakpoint) {
                width: 100%;
                height: auto;
            }
        }

        .controls {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .loading-visible {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .loading-hidden {
            display: none;
        }
    }

    .details-status {
        display: none;
        @media screen and (max-width: $xxl-breakpoint) {
            display: flex;
            width: 100%;
        }

        @media screen and (max-width: $m-breakpoint) {
            flex-direction: column;
        }
    }
}
