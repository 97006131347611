.button {
    align-items: center;
    border: none;
    cursor: pointer;
    justify-content: center;
    padding: 0.5rem 1rem;
    transition: background-color 0.4s ease-in, color 0.5s ease-in-out;
    text-decoration: none;

    &.no-border {
        border: none !important;
    }

    &.no-bg {
        background: none !important;
    }

    &.caps {
        text-transform: uppercase;
    }

    // red background white text
    &.primary {
        background-color: $wt-red;
        border: 3px solid $wt-red;
        color: $wt-grey-8;

        &:not(.no-hover):hover {
            background-color: $wt-grey-8;
            color: $wt-red;
        }

        &.disabled {
            background-color: #d15861;
            border: 3px solid #d15861;
        }
    }

    // white background red text
    &.secondary {
        background-color: $wt-grey-8;
        border: 3px solid $wt-red;
        color: $wt-red;

        &:not(.no-hover):hover {
            background-color: $wt-red;
            color: $wt-grey-8;
        }
    }

    // white background grey text, overrides prim & ``
    &.alternative {
        background-color: #fff;
        border: none;
        color: $wt-grey-2;
        transition: background-color 0.15s ease-in,
            color 0.5s cubic-bezier(0.19, 1, 0.22, 1);

        &:not(.no-hover):hover {
            background-color: $wt-grey-7;
            color: $wt-grey-1;
        }
    }

    // light
    &.light {
        color: $wt-grey-5;

        &:not(.no-hover):hover {
            color: $wt-grey-3;
            background-color: $wt-grey-8;
        }
    }

    &.border {
        &--red {
            border: 2px solid $wt-red;
        }
        &--white {
            border: 2px solid $wt-grey-8;
        }
    }

    .icon {
        margin-left: 0.5rem;

        &-w {
            background-color: $wt-grey-8;
            color: $wt-grey-6;

            &:hover {
                background-color: $wt-grey-6;
                color: $wt-grey-5;
            }
        }

        &-g {
            background-color: $wt-grey-6;
            color: $wt-grey-8;

            &:hover {
                background-color: $wt-grey-5;
                color: $wt-grey-7;
            }
        }

        &-circle {
            width: 1rem;
            height: 1rem;
            border-radius: 10rem;
            padding: 0.25rem;
        }

        &-fit {
            padding: 0;
            height: fit-content;
            width: fit-content;
        }
    }

    // &.disabled {
    //     :hover {
    //         background-color: initial;
    //         color: initial;
    //         transition: 0s;
    //     }
    // }
}
