.poi-display {
    position: absolute;
    height: 80%;
    right: 0;
    width: 25%;
    min-width: 20rem;
    transform: translateX(100%);
    transition: opacity 0.6s ease-in-out, transform 0.5s;
    display: flex;
    opacity: 0;
    flex-direction: column;

    @media screen and (max-width: $l-breakpoint) {
        height: 100%;
    }

    &.fs {
        height: 100%;
    }

    &.active {
        opacity: 1;
        transform: translateX(0);
    }

    .img-wrapper {
        height: 30%;
        width: 100%;

        .img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &.hidden {
            // display: none;
            background-color: rgba(30, 30, 30, 0.85);

            .img {
                display: none !important;
            }
        }
    }

    .content {
        padding: 1.5rem 1rem;
        padding-top: 0;
        height: 70%;
        box-sizing: border-box;
        background-color: rgba(30, 30, 30, 0.85);

        .title--wrapper {
            padding: 1rem;
            padding-left: 0;
            margin-left: 0.5rem;
            border-bottom: 1px solid $wt-grey-6;

            .title {
                font-size: 32px !important; // !!! angri, brak konsekwencji w rozmiarach trzciąki
                padding: 0;
                margin: 0;
            }
        }

        .paragraph-wrapper {
            padding: 0 0.5rem;
            box-sizing: border-box;
            margin-bottom: 2rem;
            overflow-y: auto;
            // scrollbar-width: thin;
            // scrollbar-color: $wt-grey-2 $wt-grey-6;

            .text {
                letter-spacing: 0.03rem;
                line-height: 1.5rem;
                margin-top: 1rem;
            }
        }

        // .paragraph-wrapper::-webkit-scrollbar-track {
        //     background: $wt-grey-6;
        // }

        // .paragraph-wrapper::-webkit-scrollbar-thumb {
        //     background-color: $wt-grey-2;
        //     border-radius: 6px;
        //     border: 3px solid $wt-grey-6;
        // }
        .paragraph-wrapper::-webkit-scrollbar-track {
            padding: 2px 0;
            background-color: $wt-grey-2;
        }

        .paragraph-wrapper::-webkit-scrollbar {
            width: 0.5rem;
        }

        .paragraph-wrapper::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: $wt-grey-6;
        }

        .btn-wrapper {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            width: 8rem;
        }
    }
}
