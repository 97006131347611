.trackspots {
    padding: 0 6rem;

    @media screen and (max-width: $l-breakpoint) {
        padding: 0 3rem;
    }

    @media screen and (max-width: $sm-breakpoint) {
        padding: 0 1rem;
    }
}
