.tracklocation {
    .icon {
        margin-right: 1.5rem;
        width: 40px;
        height: 26px;
    }
    .place {
        color: white;
        letter-spacing: 0.04rem;
    }
}
