.community {
    background-color: $wt-grey-1;
    width: 100%;
    height: 60rem;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: $xxl-breakpoint) {
        min-height: 60rem;
        height: fit-content;
    }
    .wrapper {
        padding: 8rem 2rem;

        @media screen and (max-width: $m-breakpoint) {
            padding: 8rem 0;
        }
    }

    .title {
        color: white;
        text-align: center;
        margin-bottom: 4rem;

        @media screen and (max-width: $s-breakpoint) {
            font-size: $font-l;
        }

        @media screen and (max-width: $xs-breakpoint) {
            font-size: $font-ml;
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        justify-content: center;
        margin: auto;

        @media screen and (max-width: $ml-breakpoint) {
            width: 100%;
        }

        .img {
            width: 20rem;
            height: 20rem;
            margin: 0.5rem;
        }
    }
}
