.filter {
    &-wrapper {
        height: fit-content;
        width: 100%;
        margin-bottom: 2rem;
    }

    &-form {
        flex-wrap: wrap;

        @media screen and (max-width: $sm-breakpoint) {
            flex-direction: column;

            * {
                margin-right: 0 !important;
            }
        }

        .filter-select {
            text-transform: uppercase;
            color: $wt-grey-4;
            padding: 1rem 0.5rem;
            padding-right: 2.5rem;
            margin-right: 0.5rem;

            border: none;
            border-bottom: 1px solid $wt-grey-6;
            border-radius: 2px;
            box-sizing: border-box;

            height: 3.5rem;
            width: 18rem;
            outline: none;

            @media screen and (max-width: $m-breakpoint) {
                font-size: $font-sm;
            }

            @media screen and (max-width: $s-breakpoint) {
                font-size: $font-s;
            }

            &:focus {
                color: $wt-grey-3;
                box-shadow: 0 0 0 1px $wt-red;
                border: none;
            }

            .option {
                border: none;

                &::after {
                    position: absolute;
                    content: "";
                    top: 0px;
                    width: 100px;
                    height: 10px;
                    background-color: red;
                    color: #ffff00;
                }

                &:first-of-type {
                    color: $wt-grey-5;
                }
            }
        }
        .input {
            padding: 1rem 3rem;
            padding-left: 1rem;
            outline: none;
            border: none;
            text-transform: uppercase;
            border-radius: 2px;
            border-bottom: 1px solid $wt-grey-6;
            margin-right: 0.5rem;

            box-sizing: border-box;
            width: 18rem; // input width does not take into account padding
            height: 3.5rem;

            @media screen and (max-width: $m-breakpoint) {
                font-size: $font-sm;
            }

            @media screen and (max-width: $s-breakpoint) {
                font-size: $font-s;
            }

            &:focus {
                border-bottom: 1px solid $wt-red;
            }
        }
        .submit {
            @media screen and (max-width: $sm-breakpoint) {
                margin-top: 2rem;
            }

            @media screen and (max-width: $s-breakpoint) {
                font-size: $font-s;
            }
        }
    }
}
