.track-statistics {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    // padding: 0.5rem;
    display: flex;
    box-sizing: border-box;
    .stat {
        margin: 0;
        &-fit {
            &--s {
                width: 4rem;
            }
            &--m {
                width: 6rem;
            }
            &--l {
                width: 10rem;
            }
        }
        .value {
            font-size: $font-ml;
            font-weight: 300;

            &--small {
                font-size: $font-ml;
            }
        }

        .unit {
            font-size: $font-m;
            text-transform: uppercase;
            font-weight: 300;

            &--small {
                font-size: $font-s;
            }
        }
        .icon {
            width: 1.75rem;
            height: 1.75rem;
            margin: auto;
        }
    }
    .text-ff {
        color: white !important;
    }

    .separator {
        margin: 0.5rem 1rem;
        height: 3rem;
        width: 1px;
        background-color: $wt-grey-5;

        &.responsive {
            &-hide {
                display: block;
            }

            &-show {
                display: none;
            }
        }
    }

    @media screen and (max-width: $xl-breakpoint) {
        //
        flex-direction: column-reverse;
        align-items: flex-start;
        .separator {
            height: 2rem;
            &.responsive {
                &-hide {
                    display: none;
                }

                &-show {
                    display: block;
                }
            }
        }
        .stat {
            justify-content: unset;
            &-fit {
                &--s {
                    width: 8rem;
                }
                &--m {
                    width: 8rem;
                }
            }

            .icon {
                &.responsive-hide {
                    display: none;
                }
            }
        }
        .part {
            position: relative;
            height: fit-content;
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 80%;
                top: 10%;
                left: 0;
                background-color: $wt-grey-6;
            }
        }
    }
}

.track-statistics {
    &.fs {
        @media screen {
            // tu wszystkie reguły jak staty są w fullscreenie
        }
    }
}
