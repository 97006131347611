.title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    // width: 100%;
    // margin: auto;

    &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $wt-red;
    }

    .section-title {
        background-color: white;
        margin: 2rem 0;
        padding: 0 3rem;
        z-index: 1;
        text-align: center;

        @media screen and (max-width: $ml-breakpoint) {
            padding: 0 1rem;
            font-size: $font-l;
        }
        @media screen and (max-width: $m-breakpoint) {
            // text-align: center;
            font-size: $font-ml;
            padding: 0 0.25rem;
        }

        @media screen and (max-width: $s-breakpoint) {
            font-size: $font-sm;
            margin: 1rem 0;
        }
    }
}
