.expeditions-list {
    width: 100%;
    min-height: 50rem;
    .inner {
        padding: 6rem;
        padding-top: 2rem;

        @media screen and (max-width: $xl-breakpoint) {
            padding: 2rem;
        }
        .items-container {
            width: 100%;
            height: fit-content;
        }
    }
}
