.trackspot {
    height: fit-content;
    margin-bottom: 1rem;
    width: 100%;

    .img-wrapper {
        height: 24rem;
        width: 24rem;
        .img {
            margin: 0;
            height: 24rem;
            width: 24rem;
        }
    }

    .data-wrapper {
        box-sizing: border-box;
        height: fit-content;
        min-height: 24rem;
        padding: 2rem 4rem;
        margin-left: 2rem;
        background-color: #f9f9f9;

        .title--wrapper {
            border-bottom: 1px solid $wt-grey-5;
            padding: 1rem 0;
            width: 100%;

            .title {
                color: $wt-red;
            }
        }

        .desc {
            &--short {
                margin: 1rem 0;
            }

            &--long {
                display: none;
                margin: 1rem 0;

                &.visible {
                    display: inline;
                }
            }

            .text {
                color: $wt-grey-3;
            }
        }

        .btn-wrapper {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btn {
                padding: 0.5rem 1rem;
                background-color: $wt-grey-7;
                color: $wt-grey-4;
                &:hover {
                    color: $wt-grey-2;
                }
            }

            .icon {
                transition: transform 0.3s;
                transform: rotate(90deg);

                &.more-visible {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    @media screen and (max-width: $xl-breakpoint) {
        .img-wrapper {
            height: 20rem;
            width: 20rem;
            .img {
                height: 20rem;
                width: 20rem;
            }
        }

        .data-wrapper {
            min-height: 20rem;
            padding: 2rem;
        }
    }

    @media screen and (max-width: $l-breakpoint) {
        .img-wrapper {
            height: 16rem;
            width: 16rem;
            .img {
                height: 16rem;
                width: 16rem;
            }
        }

        .data-wrapper {
            min-height: 16rem;
            padding: 2rem;
        }
    }

    @media screen and (max-width: $ml-breakpoint) {
        flex-direction: column;
        .img-wrapper {
            height: 12rem;
            width: 12rem;
            .img {
                height: 12rem;
                width: 12rem;
            }
        }

        .data-wrapper {
            min-height: fit-content;
            padding: 2rem;
            margin-left: 0;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-width: $sm-breakpoint) {
        align-items: center;

        .data-wrapper {
            padding: 1rem;
        }
    }
}
