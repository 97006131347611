.control-bar {
    margin-top: 1rem;
    margin-left: 1rem;

    .part {
        background-color: $wt-red;
        width: fit-content;
        height: 3rem;

        .icon {
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            transition: background-color 0.2s linear;

            &:not(.active):hover {
                transform: scale(1.1);
            }
            &.bw {
                margin-right: 0.5rem;
            }
            &.fs {
                width: 1.5rem;
                height: 1.5rem;
                margin: 0.75rem;
                margin-right: 1.5rem;
            }

            &.speed {
                padding-left: 0.5rem;
                padding-right: 0.5rem;

                &.active {
                    cursor: default;
                    background-color: $wt-red--dark;
                }
            }
        }

        .separator {
            margin: 0.5rem 1rem;
            width: 1px;
            height: 2rem;
            background-color: $wt-grey-8;
            opacity: 0.7;
        }
    }

    .logo-wrapper {
        margin-right: 1rem;

        .logo {
            width: 2rem;
            height: 2rem;
            margin: 0.5rem;
        }
    }

    .skip-wrapper {
        margin-left: 1rem;
        margin-right: 0;
    }
}
